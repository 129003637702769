
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Hero, WhatsAppWidget, Nav, HowDoesItWorkSection, DesignedFor, AdvantagesOfConfirmafy, Faq, Page, Footer, SendMeReminderExample, AutoAgendaExample, PricingSlider, GetPhoneNumberPopUp, CustomerLogos, } from "ui_components";
import { Testimonials } from "ui_components/Home";
import useTranslateFunction from "@hooks/useTranslateFunction";
import { useRouter } from "next/router";
import Link from "next/link";
export default function Home() {
    const { t } = useTranslateFunction();
    const { locale } = useRouter();
    return (<Page title={t("home_meta_Confirmación de citas y eventos por Whatsapp | Confirmafy")} description={t("home_meta_App para confirmar citas, enviar recordatorios y manejar tu agenda de clientes desde WhatsApp.")}>
      <div>
        <Nav sticky></Nav>
        <WhatsAppWidget></WhatsAppWidget>
        <Hero></Hero>
        <GetPhoneNumberPopUp></GetPhoneNumberPopUp>
        <CustomerLogos></CustomerLogos>
        <DesignedFor></DesignedFor>
        <HowDoesItWorkSection></HowDoesItWorkSection>
        <SendMeReminderExample></SendMeReminderExample>
        {locale === "es" && <AutoAgendaExample></AutoAgendaExample>}
        <Testimonials></Testimonials>
        <AdvantagesOfConfirmafy></AdvantagesOfConfirmafy>
        <PricingSlider></PricingSlider>
        <Faq></Faq>
        <Footer></Footer>
      </div>
    </Page>);
}

    async function __Next_Translate__getStaticProps__193bc8f8837__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193bc8f8837__ as getStaticProps }
  